import { CrystallizeCustomerUpdateOp } from './interfaces.js';
import { STRIPE_PAYMENT_METHOD_ID } from './constants.js';
import config from '../../../../app-config.js';
export const getPaymentMethodDetails = async paymentMethodId => {
  if (!paymentMethodId) throw new Error(`We couldn't get your payment details.`);
  const query = `
  query GET_PAYMENT_METHOD($id: String!){
     paymentProviders {
      stripe {
        retrievePaymentMethod(id: $id)
      }
    }
  }`;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          id: paymentMethodId
        }
      })
    });
    const {
      data,
      errors
    } = await response.json();
    if (errors) return new Error(`We are having problems getting your payment information. Please try again in few minutes!`);
    return data.paymentProviders.stripe.retrievePaymentMethod;
  } catch (err) {
    throw new Error(` Unfortunately, we couldn't retrieve your payment information. Please try again in sometime`);
  }
};
export const getOrdersForCustomer = async customerIdentifier => {
  if (!customerIdentifier) throw new Error('customerIdentifier is needed to fetch orders');
  const query = `
  query GET_ALL_ORDERS($customerIdentifier: String!){
     orders {
        getAll(customerIdentifier: $customerIdentifier)
    }
  }`;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          customerIdentifier: customerIdentifier
        }
      })
    });
    const {
      data
    } = await response.json();
    if (data.orders.getAll.errors) return new Error(`We couldn't find your orders`);else return data.orders.getAll;
  } catch (err) {
    throw new Error(`Unfortunately, we cannot retrieve the orders at the moment. Please refresh the page or try again in some time!`);
  }
};
export const getLastSuccessPayment = orders => orders.filter(order => order.status.toLowerCase() === 'paid')[0];
export const getBalanceDue = orders => {
  const pendingPayments = orders.filter(order => order.status.toLowerCase() !== 'paid');
  if (pendingPayments.length === 0) return 0;
  return pendingPayments.reduce((a, b) => a + b.total.gross, 0);
};
export const getPaymentMethodId = meta => {
  if (!meta || meta.length === 0) return '';
  const paymentMethod = meta === null || meta === void 0 ? void 0 : meta.filter(m => m.key === STRIPE_PAYMENT_METHOD_ID)[0];
  return paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.value;
};
export const detachCardInStripe = async paymentMethodId => {
  if (!paymentMethodId) throw new Error(`we couldn't delete your card from our system.`);
  const query = `
  mutation DETACH_PAYMENT_METHOD($id: String!){
     paymentProviders {
      stripe {
        detachPaymentMethod(id: $id)
      }
    }
  }`;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          id: paymentMethodId
        }
      })
    });
    const {
      data,
      errors
    } = await response.json();
    if (errors) return new Error(`We encountered a problem while deleting your credit card. Please try again in few minutes!`);else return data.paymentProviders.stripe.detachPaymentMethod;
  } catch (error) {
    throw new Error(`Sorry we are unable to delete your credit card at the moment. Please try again in few minutes!`);
  }
};
export const updatePaymentMethodInCrystallize = async (customerData, operation, stripePaymentMethodId) => {
  var _customerData$meta, _updatedMeta;

  if (operation === CrystallizeCustomerUpdateOp.DELETE_PAYMENT_METHOD && (!customerData || !customerData.meta || customerData.meta.length === 0)) throw new Error(`Sorry, we cannot delete this payment method`);
  if (operation === CrystallizeCustomerUpdateOp.ADD_PAYMENT_METHOD && !stripePaymentMethodId) throw new Error(`Sorry, we couldn't add this payment method.`);
  let updatedMeta;
  if (operation === CrystallizeCustomerUpdateOp.DELETE_PAYMENT_METHOD) updatedMeta = customerData === null || customerData === void 0 ? void 0 : (_customerData$meta = customerData.meta) === null || _customerData$meta === void 0 ? void 0 : _customerData$meta.filter(m => m.key !== STRIPE_PAYMENT_METHOD_ID);
  if (operation === CrystallizeCustomerUpdateOp.ADD_PAYMENT_METHOD) updatedMeta = customerData.meta ? [...customerData.meta, {
    key: STRIPE_PAYMENT_METHOD_ID,
    value: stripePaymentMethodId
  }] : [{
    key: STRIPE_PAYMENT_METHOD_ID,
    value: stripePaymentMethodId
  }];
  const {
    firstName,
    lastName,
    email,
    addresses,
    taxNumber,
    companyName,
    externalReferences,
    identifier
  } = customerData; // @ts-ignore

  addresses === null || addresses === void 0 ? void 0 : addresses.map(a => delete a.__typename);
  externalReferences === null || externalReferences === void 0 ? void 0 : externalReferences.map(e => delete e.__typename);
  (_updatedMeta = updatedMeta) === null || _updatedMeta === void 0 ? void 0 : _updatedMeta.map(e => delete e.__typename);
  const customer = {
    firstName,
    lastName,
    email,
    taxNumber,
    companyName,
    addresses,
    externalReferences,
    meta: updatedMeta
  };
  const query = `
      mutation UPDATE_CRYSTALLIZE_CUSTOMER($identifier: String!, $customer: UpdateCustomerInput!) {
        customers {
          update(identifier: $identifier, customer: $customer)
        }
      }
    `;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          identifier,
          customer
        }
      })
    });
    const json = await response.json();
    return json.data.customers.update.identifier;
  } catch (error) {
    throw new Error(`We couldn't delete your payment method. Please try again in few minutes!`);
  }
};
export const getSingleCustomer = async customerIdentifier => {
  if (!customerIdentifier) throw new Error(`Sorry! we couldn't get the card information!`);
  const query = `
  query GET_CUSTOMER($identifier: String!){
     customers {
      get(identifier: $identifier)
    }
  }`;

  try {
    var _data$customers$get;

    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          identifier: customerIdentifier
        }
      })
    });
    const {
      data
    } = await response.json();
    if ((_data$customers$get = data.customers.get) !== null && _data$customers$get !== void 0 && _data$customers$get.errors) return new Error(` something is not right, we couldn't retrieve your details.`);else return data.customers.get;
  } catch (error) {
    throw new Error(` we couldn't retrieve your details. Please refresh your browser and try again`);
  }
};