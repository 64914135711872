import Particle from '../../../icons/svg/particle.svg';
import Atom from '../../../icons/svg/atom.svg';
import Crystal from '../../../icons/svg/crystal.svg';
const FreePlan = {
  name: 'Particle',
  subHeading: 'Free tier with pay as you go',
  image: Particle,
  priceInfo: {
    price: 'FREE',
    meta: '/ forever + metering'
  },
  features: [{
    text: 'Unlimited users',
    bold: true
  }, {
    text: 'Full feature set',
    bold: true
  }, {
    text: 'Unlimited languages',
    bold: true
  }, {
    text: 'Community support',
    bold: false
  }, {
    text: 'Online documentation',
    bold: false
  }, {
    text: '50 orders per month',
    bold: false
  }, {
    text: '1K catalogue items',
    bold: false
  }, {
    text: '5GB bandwidth per month',
    bold: false
  }, {
    text: '25K API calls per month',
    bold: false
  }]
};
const AtomPlan = {
  name: 'Atom',
  subHeading: 'Engineering direct support',
  image: Atom,
  priceInfo: {
    price: '$299',
    meta: '/ month + metering'
  },
  features: [{
    text: 'Unlimited users',
    bold: true
  }, {
    text: 'Full feature set',
    bold: true
  }, {
    text: 'Unlimited languages',
    bold: true
  }, {
    text: '50-60% reduced pricing on metered extra',
    bold: true
  }, {
    text: 'Professional SLA',
    bold: true
  }, {
    text: 'Support from engineering via Slack',
    bold: true
  }, {
    text: 'Community support',
    bold: false
  }, {
    text: 'Online documentation',
    bold: false
  }, {
    text: '1K orders per month',
    bold: false
  }, {
    text: '100K catalogue items',
    bold: false
  }, {
    text: '50GB bandwidth per month',
    bold: false
  }, {
    text: '500K API calls per month',
    bold: false
  }]
};
const CrystalPlan = {
  name: 'Crystal',
  subHeading: 'Engineering direct support, peace of mind pricing',
  image: Crystal,
  priceInfo: {
    price: 'Custom',
    meta: '/ month'
  },
  features: [{
    text: 'Unlimited users',
    bold: true
  }, {
    text: 'Full feature set',
    bold: true
  }, {
    text: 'Unlimited languages',
    bold: true
  }, {
    text: 'Custom reduced pricing on metered extra',
    bold: true
  }, {
    text: 'Enterprise SLA',
    bold: true
  }, {
    text: 'Dedicated support team on Slack',
    bold: true
  }, {
    text: 'Support from engineering via Slack',
    bold: true
  }, {
    text: 'Community support',
    bold: false
  }, {
    text: 'Online documentation',
    bold: false
  }, {
    text: 'Custom orders per month',
    bold: false
  }, {
    text: 'Custom catalogue items',
    bold: false
  }, {
    text: 'Custom bandwidth per month',
    bold: false
  }, {
    text: 'Custom API calls per month',
    bold: false
  }]
};
export const PlansData = [{ ...FreePlan
}, { ...AtomPlan
}, { ...CrystalPlan
}];