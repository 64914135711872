import styled from '../../../../_snowpack/pkg/styled-components.js'; // @ts-ignore

import { colors } from '../../../css/index.js';
export const PlanContainer = styled.div.withConfig({
  displayName: "styles__PlanContainer",
  componentId: "klb84e-0"
})(["display:grid;grid-template-columns:repeat(3,2fr);grid-gap:50px;"]);
export const Plan = styled.div.withConfig({
  displayName: "styles__Plan",
  componentId: "klb84e-1"
})(["display:grid;grid-template-rows:100px 110px auto;grid-gap:10px;button,a{width:100%;border:2px solid transparent;}"]);
export const HeadingSubtitleImg = styled.div.withConfig({
  displayName: "styles__HeadingSubtitleImg",
  componentId: "klb84e-2"
})(["display:grid;grid-template-columns:1fr 100px;p{line-height:1.4em;}"]);
export const Pricing = styled.h2.withConfig({
  displayName: "styles__Pricing",
  componentId: "klb84e-3"
})(["font-size:30px;margin:0;font-family:'Roboto',serif;font-weight:bold;color:", ";display:flex;align-items:center;"], colors.frostbite);
const shadowSize = '0.025em';
export const PlanName = styled.h2.withConfig({
  displayName: "styles__PlanName",
  componentId: "klb84e-4"
})(["font-size:", ";font-family:'Roboto Slab',sans-serif;margin:0 0 15px;color:", ";text-shadow:-", " -", " 0 #828a90,0 -", " 0 #828a90,", " -", " 0 #828a90,", " 0 0 #828a90,", " ", " 0 #828a90,0 ", " 0 #828a90,-", " ", " 0 #828a90,-", " 0 0 #828a90;"], props => props.size ? props.size : `3rem`, props => props.plan === 'particle' ? `#d3cff4` : props.plan === 'atom' ? `#FFEFD2` : `#C2E5E1`, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize, shadowSize);
export const StyledList = styled.li.withConfig({
  displayName: "styles__StyledList",
  componentId: "klb84e-5"
})(["font-family:'Roboto','sans-serif';font-size:1rem;color:", ";font-weight:", ";"], props => props.bold ? `${colors.frostbite}` : `gray`, props => props.bold ? `bold` : `normal`);
export const CostInfo = styled.div.withConfig({
  displayName: "styles__CostInfo",
  componentId: "klb84e-6"
})(["display:flex;"]);
export const PricingPlanImg = styled.img.withConfig({
  displayName: "styles__PricingPlanImg",
  componentId: "klb84e-7"
})(["height:80px;margin-top:-15px;width:80px;"]);
export const PricingMetaText = styled.p.withConfig({
  displayName: "styles__PricingMetaText",
  componentId: "klb84e-8"
})(["margin-left:10px;color:", ";"], colors.label);