import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

// Public stuff
const CLIENT_CATALOGUE_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CLIENT_CATALOGUE_URL;
const CLIENT_SEARCH_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CLIENT_SEARCH_URL;
const API_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL;
const GOOGLE_CLOUD_API = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_GOOGLE_CLOUD_API;
const ENVIRONMENT = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENVIRONMENT; // When running the API locally

const LOCAL_BACKEND = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_LOCAL_BACKEND === 'true';
const CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API;
const STRIPE_PUBLISHABLE_KEY = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_STRIPE_PUBLISHABLE_KEY;
const SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GENERATE_INVOICE_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GENERATE_INVOICE_URL;
export default {
  ENVIRONMENT,
  API_URL,
  SIGNIN_URL: `${API_URL}/signin`,
  SIGN_OUT_URL: `${API_URL}/signout`,
  GRAPH_URL: LOCAL_BACKEND ? API_URL : `${API_URL}/graphql`,
  CLIENT_CATALOGUE_URL,
  CLIENT_SEARCH_URL,
  TENANT_OVERRIDE: window.__tenant_override,
  ACCESS_TOKEN_ID: window.__access_token_id,
  ACCESS_TOKEN_SECRET: window.__access_token_secret,
  GOOGLE_CLOUD_API,
  CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API,
  STRIPE_PUBLISHABLE_KEY,
  SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GENERATE_INVOICE_URL
};