export const monthNumberToName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'December'];
export const STRIPE_PAYMENT_METHOD_ID = 'stripePaymentMethodId';
export const STRIPE_CUSTOMER_ID = 'stripeCustomerId';
export const DEFAULT_PARTICLE_PLAN_INFO = {
  maxApiCalls: 25000,
  maxApiCallsChunkSize: 25000,
  maxBandwidth: 5,
  maxItems: 1000,
  maxOrders: 50,
  name: 'Particle',
  perExtraApiCalls: 2,
  perExtraBandwidth: 0.3,
  perExtraItems: 0.05,
  perExtraOrders: 0.5,
  price: 0
};